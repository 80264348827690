import { Grid, Typography, Button, Box, Divider } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getPlanName, getPlanTierByCode } from "Utils/Components/Plan";
import { getDonationPlanCode } from "Utils/Components/Plan";
import { PlanCode } from "../../../../types/Plan";
import {
  MINIMUM_MONTHLY_DONATION_FOR_GIFT,
  PlanTierKey,
} from "Utils/Constants";
import JGCard from "../JGCard";
import { PlanFeaturesList } from "../Plan";
import { DonationType } from "Utils/Constants/Donate";
import { formatCurrency } from "Utils/Format";
import { DEFAULT_DONATION_AMOUNTS } from "Pages/Components/Checkout/Constants";
import { DonationGift } from "../../../../types/Donate";
import "../../../i18n/config";
import { getI18n, useTranslation } from "react-i18next";

export function DonationSummary(props: {
  donationType: DonationType;
  autoRenew: boolean;
  donationAmount: number;
  gift: DonationGift | null;
  donationAdditionalAmount: number;
  borderless?: boolean;
  transparent?: boolean;
  setDonationAmount?: (val: number) => void;
  shippingFee: number;
  coupon: Coupon;
  action?: React.ReactNode;
  freeTrial?: boolean;
}) {
  const { t } = useTranslation();

  const donationType = () => {
    switch (props.donationType) {
      case DonationType.annual:
        return t("donationPage.annually");
      case DonationType.monthly:
        return t("donationPage.monthly");
      case DonationType.oneTime:
        return props.autoRenew
          ? t("donationPage.annually")
          : t("donationPage.oneTime");
    }
  };

  const [showPlanPerks, setShowPlanPerks] = useState(false);
  const [planName, setPlanName] = useState("");
  const [tier, setTier] = useState<PlanTierKey>("");

  /**
   * The number of months of standard plans that are purchased with a one-time
   * donation that's less than a one-time premium plan purchase
   *
   * Null if not applicable to the selected plan and donation type.
   */
  const [standardPlanMonths, setStandardPlanMonths] = useState<number | null>(
    null
  );
  const [showPlan, setShowPlan] = useState<boolean>(true);

  useEffect(() => {
    const code = getDonationPlanCode(
      props.donationAmount,
      props.donationType
    ) as PlanCode;
    const tierKey = getPlanTierByCode(code).key;
    setPlanName(getPlanName(code));
    setTier(tierKey);
    setStandardPlanMonths(
      props.donationType === DonationType.oneTime &&
        tierKey === "premium-plus-lite"
        ? Math.max(
            1,
            Math.floor(
              props.donationAmount /
                DEFAULT_DONATION_AMOUNTS[DonationType.monthly]
            )
          )
        : null
    );

    if (
      props.donationType === DonationType.monthly &&
      props.donationAmount < DEFAULT_DONATION_AMOUNTS.m
    ) {
      setShowPlan(false);
    } else {
      setShowPlan(true);
    }
  }, [props.donationAmount, props.donationType]);

  const getCouponAmount = useCallback(() => {
    if (props.coupon?.discountType === "fixed_amount") {
      return props.coupon?.discountAmount;
    } else if (props.coupon?.discountType === "percentage") {
      return Math.round(
        (props.coupon!.discountAmount *
          (props.donationAmount + props.donationAdditionalAmount)) /
          100
      );
    }
    return 0;
  }, [props.coupon, props.donationAmount, props.donationAdditionalAmount]);

  const getTotal = useCallback(() => {
    return (
      props.donationAmount +
      props.donationAdditionalAmount -
      getCouponAmount() +
      props.shippingFee
    );
  }, [
    props.donationAmount,
    props.donationAdditionalAmount,
    props.shippingFee,
    getCouponAmount,
  ]);
  const { language } = getI18n();
  var isJapan = false;
  var userLang = language;
  if (userLang && (userLang.includes("ja") || userLang.includes("JP")))
    isJapan = true;

  return (
    <>
      <Box
        sx={{
          mr: { xs: 0, md: 2, lg: 0 },
        }}
      >
        <JGCard
          sx={{
            p: 2,
          }}
          borderless={props.borderless}
          transparent={props.transparent}
          ContentProps={{
            sx: {
              p: { xs: 0, md: 2 },
            },
          }}
        >
          <Grid container spacing={2}>
            {!props.borderless && (
              <Grid item xs={12} sx={{ mb: 2, textAlign: "center" }}>
                <Typography variant="h6">
                  {t("donationPage.summary")}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ fontWeight: "bold", flexGrow: 1 }}>
                  {t("donationPage.title")} ({donationType()})
                </Box>
                <Box textAlign="right">
                  ${(props.donationAmount / 100).toFixed(2)}
                </Box>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ fontWeight: "bold", flexGrow: 1 }}>
                  {showPlan && (
                    <Typography variant="body2">
                      {t("donationPage.plan")}: {planName}
                      {standardPlanMonths && ` (x ${standardPlanMonths}mo)`}
                    </Typography>
                  )}
                </Box>
              </Box>

              {showPlan && (
                <span>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ fontWeight: "bold", flexGrow: 1 }}>
                      <Button
                        variant="text"
                        size="small"
                        disableRipple
                        sx={{
                          px: 0,
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          "&:hover": {
                            color: "primary.light",
                            background: "none",
                          },
                        }}
                        onClick={() => setShowPlanPerks(!showPlanPerks)}
                      >
                        {showPlanPerks
                          ? t("donationPage.hide")
                          : t("donationPage.show")}{" "}
                        {t("donationPage.planPerks")}
                      </Button>
                    </Box>
                  </Box>

                  {showPlanPerks && (
                    <PlanFeaturesList
                      tier={tier}
                      giftEnabled={
                        props.donationType === DonationType.monthly
                          ? props.donationAmount >=
                            MINIMUM_MONTHLY_DONATION_FOR_GIFT
                          : true
                      }
                      size="small"
                      sx={{ pt: 0 }}
                    />
                  )}
                </span>
              )}
            </Grid>

            {props.gift && !isJapan && !props.freeTrial ? (
              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ fontWeight: "bold", flexGrow: 1 }}>
                    Thank you gift
                  </Box>
                  <Box textAlign="right">FREE</Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ fontWeight: "bold", flexGrow: 1 }}>
                    <Typography variant="body2">{props.gift.name}</Typography>

                    {props.gift.size?.name && (
                      <Typography
                        variant="caption"
                        component="p"
                        sx={{
                          color: "text.secondary",
                          fontStyle: "italic",
                          lineHeight: 1.538,
                        }}
                      >
                        Size: {props.gift.size?.name}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ flexGrow: 1 }}>{t("donationPage.subtotal")}</Box>
                <Box textAlign="right">
                  {formatCurrency(props.donationAmount / 100)}
                </Box>
              </Box>

              {props.donationAdditionalAmount > 0 ? (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ flexGrow: 1 }}>{t("donationPage.fee")}</Box>
                  <Box textAlign="right">
                    {formatCurrency(props.donationAdditionalAmount / 100)}
                  </Box>
                </Box>
              ) : (
                <></>
              )}

              {props.gift && props.gift.id ? (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ flexGrow: 1 }}>Shipping</Box>
                  <Box textAlign="right">
                    {props.shippingFee
                      ? formatCurrency(props.shippingFee / 100)
                      : "FREE"}
                  </Box>
                </Box>
              ) : (
                <></>
              )}

              {props.coupon && props.coupon.discountAmount ? (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ flexGrow: 1 }}>
                    {props.coupon.formatted} {t("donationPage.coupon")}
                  </Box>
                  <Box textAlign="right">
                    {formatCurrency((-1 * getCouponAmount()) / 100)}
                  </Box>
                </Box>
              ) : (
                <></>
              )}

              <Typography
                variant="subtitle2"
                component="div"
                sx={{ display: "flex", flexDirection: "row", mt: 1 }}
              >
                <Box sx={{ fontWeight: "bold", flexGrow: 1 }}>
                  {t("donationPage.total")}
                </Box>
                <Box sx={{ fontWeight: "bold" }} textAlign="right">
                  {formatCurrency(getTotal() / 100)}
                </Box>
              </Typography>
            </Grid>
            {props.action && (
              <Grid item xs={12}>
                {props.action}
              </Grid>
            )}
          </Grid>
        </JGCard>
      </Box>
    </>
  );
}
