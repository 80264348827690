import { Box, Button, Stack, StackProps, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import JGIcon from "Pages/Components/JGIcon";
import { normalizeSx } from "Utils/Components/normalizeSx";
import "../../../i18n/config";
import { useTranslation } from "react-i18next";

export type CheckoutStepperNavProps = StackProps & {
  fullWidth?: boolean;
  hidePrevious?: boolean;
  donateOnly?: boolean;
  freeTrial?: boolean;
  // If provided, will show a small hint below the donate button if the user cannot proceed.
  showHint?: boolean;
  loading: boolean;
  emailExists: boolean;
  activeStep: number;
  steps: any[];
  canGoBack: () => boolean;
  canSubmit: () => boolean;
  isStepValid: () => boolean;
  onBack: () => void;
  onNext: () => void;
};

const CheckoutStepperNav = (props: CheckoutStepperNavProps) => {
  const {
    fullWidth = false,
    hidePrevious = false,
    donateOnly = false,
    showHint = false,
    freeTrial = false,
    loading,
    activeStep,
    steps,
    emailExists,
    isStepValid,
    canGoBack,
    canSubmit,
    onBack,
    onNext,
    sx,
    ...rest
  } = props;

  const { t } = useTranslation();

  const isFinalStep = donateOnly || activeStep === steps.length - 1;
  const showPreviousButton = !donateOnly && !hidePrevious && canGoBack();
  const donateIsValid = isFinalStep ? canSubmit() : isStepValid();

  return (
    <Stack
      spacing={1}
      direction={fullWidth ? "column-reverse" : "row"}
      sx={[
        {
          alignItems: fullWidth ? "stretch" : "center",
          justifyContent: showPreviousButton ? "space-between" : "flex-end",
          mt: 4,
        },
        ...normalizeSx(sx),
      ]}
      {...rest}
    >
      {showPreviousButton && !emailExists && (
        <Button
          size="large"
          color="neutral"
          variant="outlined"
          disabled={activeStep === 0}
          onClick={onBack}
          sx={{
            flex: 1,
            maxWidth: fullWidth ? undefined : 190,
            color: "text.primary",
          }}
          startIcon={<JGIcon is={BsArrowLeft} style={{ fontSize: "1rem" }} />}
        >
          Previous
        </Button>
      )}

      <Box sx={{ flex: 1, maxWidth: fullWidth ? undefined : 190 }}>
        {!emailExists && (
          <LoadingButton
            size="large"
            fullWidth
            loading={isFinalStep && loading}
            endIcon={
              !isFinalStep ? (
                <JGIcon is={BsArrowRight} style={{ fontSize: "1rem" }} />
              ) : undefined
            }
            onClick={onNext}
            disabled={loading || !donateIsValid}
            sx={{
              padding: "20px 30px",
            }}
          >
            {isFinalStep
              ? freeTrial
                ? "Unlock Free Membership "
                : t("donationPage.title")
              : "Next"}
          </LoadingButton>
        )}

        {showHint && !donateIsValid && (
          <Typography
            variant="caption"
            component="p"
            sx={{ mt: 1, color: "text.secondary", textAlign: "center" }}
          >
            {t("donationPage.pleaseComplete")}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

export default CheckoutStepperNav;
